import {
  log
} from "../../../../Portal/src/js/logger";
import {
  http
} from "../../../../Portal/src/js/http";
import {
  queryString
} from "../../../../Portal/src/js/utils/helpers";
import {
  encodeAgentUrl,
  encodePropertyUrl
} from "../utils/urlEncoding";
import {
  filterOptions,
  filterQueries
} from "../utils/constants";
(function () {
  $(document).ready(() => {
    const mn = $("#property-listings");
    if (mn.length) {
      const dt = mn[0].dataset;
      new Vue(new PropListingsPageVueApp());
    }
  });
})();

export class PropListingsPageVueApp {
  constructor() {
    this.el = "#property-listings";
    this.data = Object.assign({}, this.data, filterOptions);
    this.data.query = Object.assign({}, this.data.query, filterQueries);
  }
  el = "#property-listings";
  data = {
    id: 0,
    query: {
      q: "",
      channel: 0,
      sortBy: "",
      maxPrice: "",
      bedrooms: "",
      bathrooms: "",
      propertyTypeId: "",
      propTypeHasBedrooms: true,
      propTypeHasBathrooms: true,
    },
    paged: {},
    items: [],
    propertytypes: [],
  };
  created = function () {
    this.query = Object.assign({}, this.query, filterQueries);
  };
  mounted = function () {
    let map = queryString();
    if (map) {
      this.query = Object.assign({}, this.query, map);
    }
    this.getPropTypes();
    this.getItems();
  };
  methods = {
    addMarkers: function(input){
      // let latitudes = [];
      // let longitudes = [];
      // let bounds = new google.maps.LatLngBounds();
      // for (let i = 0; i < input.length; i++) {
      //       let latitude = input[i].location.latitude;
      //       let longitude = input[i].location.longitude;
      //       latitudes.push(latitude);
      //       longitudes.push(longitude);
      //       // let latLng = new google.maps.LatLng(latitudes[i], longitudes[i]);
      //       // bounds.extend(latLng);
      //   }
        

        // latitudes.sort((a, b) => a - b);
        // longitudes.sort((a, b) => a - b);
        // let longitudeLength=latitudes.length;
        // let zoomCustom=15;
        // switch(true){
        //    case longitudeLength >= 2 && longitudeLength <= 5:
        //     zoomCustom=13;
        //     break;
        //     case longitudeLength >= 6 && longitudeLength <= 10:
        //     zoomCustom=8;
        //     break;
        //     case longitudeLength >= 11 && longitudeLength <= 15:
        //     zoomCustom=7;
        //     break
        //     case longitudeLength >= 15 && longitudeLength <= 20:
        //     zoomCustom=6;
        //     break;
        //     case longitudeLength >= 21:
        //     zoomCustom=5;
        //     default:
        //     zoomCustom=15;
        // }
       
       //culculated map view
        // var mapOptions={
        //     center: {lat:latitudes[0], lng:longitudes[longitudes.length-1]},
        //     zoom: zoomCustom
        // }
        //Nairobi and environs on map
        var mapOptions={
            center: {lat:-1.286389, lng:36.817223},
            zoom: 12
        }

        // let mapCenter = bounds.getCenter();

        // var mapOptions={
        //   center: mapCenter,
        //   zoom: 12
        // }
        var myMap=new google.maps.Map(document.getElementById('map-column'),mapOptions)    

        // let mapZoom = this.getZoomLevel(bounds, myMap); 
        // myMap.setCenter(bounds.getCenter());
        // myMap.setZoom(mapZoom);
       for(var i=0;i<input.length;i++){
         new google.maps.Marker({position:{lat:input[i].location.latitude, lng: input[i].location.longitude},title:input[i].location.name,map:myMap});
       }
        },
    initMap: function(){
      console.log("items",this.paged.items)
      this.addMarkers(this.paged.items);
    },
    getZoomLevel:function(bounds, map) {
        const WORLD_DIM = { height: 256, width: 256 };
        const ZOOM_MAX = 21;
    
        let ne = map.getProjection().fromLatLngToPoint(bounds.getNorthEast());
        let sw = map.getProjection().fromLatLngToPoint(bounds.getSouthWest());
    
        let zoom = Math.min(
            Math.floor(Math.log2(WORLD_DIM.width / (ne.x - sw.x))),
            Math.floor(Math.log2(WORLD_DIM.height / (ne.y - sw.y)))
        );
    
        return Math.min(Math.max(0, zoom), ZOOM_MAX);
    },
    getItems: function () {
      let str = this.getQueryString();
      http.get(`/api/listings?${str}`, false).then((r) => {
        log(r);
        if (r) {
          this.paged = r;
          this.initMap();
        }
      });
    },
    getQueryString: function () {
      const aa = this.query;
      let bb = "";
      Object.keys(this.query).forEach((x) => {
        if (aa[x] != "" || aa[x] != undefined) {
          bb += `&${x}=${aa[x]}`;
        }
      });
      bb = bb.substr(1);
      log(bb);
      return bb;
    },
    getPropTypes: function () {
      http.get("/api/propertytypes", false).then((r) => {
        this.propertytypes = r;
        setTimeout(() => {
          $("select.nice").niceSelect();
          $("#propertyTypeInput").on("change", (e) => {
            this.onPropertyTypeChange(e);
          });
          $("select.listen-change").on("change", (e) => {
            this.onFilterChange(e);
          });
        }, 200);
      });
    },

    changeChannel: function (ct) {
      this.query.channel = ct;
      this.getItems();
    },

    onFilterChange: function (e) {
      log(e);
      if (this.query.hasOwnProperty(e.target.name)) {
        this.query[e.target.name] = e.target.value;
        this.getItems();
      }
    },
    onPropertyTypeChange: function (e) {
      log(e);
      const id = parseInt(e.target.value);
      log(id);
      if (!isNaN(id)) {
        this.query.propertyTypeId = id;
        const prop = this.propertytypes.find((x) => x.id == id);
        if (prop) {
          if (prop.hasBedrooms) {
            this.query.propTypeHasBedrooms = true;
          } else {
            this.query.propTypeHasBedrooms = false;
            this.query.bedrooms = "";
          }
          if (prop.hasBathrooms) {
            this.query.propTypeHasBathrooms = true;
          } else {
            this.query.propTypeHasBathrooms = false;
            this.query.bathrooms = "";
          }
        }
      }
    },

    encodeUrl: function (dt) {
      return encodePropertyUrl(dt);
    },
    encodeAgentUrl: function (dt) {
      return encodeAgentUrl(dt);
    },
    openSingleProperty(item){
        var url=encodePropertyUrl(item);
        window.location.href =url;
    },
    
  };
}