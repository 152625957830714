import { vars } from "../variables";

export const formatNumber = (val) => {
  val = val === undefined ? 0 : parseFloat(val);
  if (isNaN(val)) {
    return 0;
  } else {
    var formatter = new Intl.NumberFormat(vars.numberFormat, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    let a = val % 1;
    let b = parseInt(val);
    if (a > 0) {
      b++;
    }
    return formatter.format(b);
  }
};
export const formatNumberDecimals = (val, decimals) => {
  val = val === undefined ? 0 : parseFloat(val);
  if (isNaN(val)) {
    return 0;
  } else {
    var formatter = new Intl.NumberFormat(vars.numberFormat, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
    return formatter.format(val);
  }
};
