"use strict";

import { vars } from "./variables";
const notifyDefaults = {
  position: "topCenter",
};

/**
 * Displays an information pop-up notification message to the user.
 * @param {string} title Info message title.
 * @param {string} message Info message.
 */
function infoNotify(title, message) {
  if (!title || title.length < 1) {
    title = "Info";
  }
  if (!message || message.length < 1) {
    message = "";
  }
  iziToast.info({
    title: title,
    message: message,
    position: notifyDefaults.position,
    theme: "light",
    displayMode: 2,
    timeout: vars.notifyTimeout,
  });
}
/**
 * Displays a success pop-up notification message to the user.
 * @param {string} message Success message.
 * @param {string} title Success message title.
 */
export const successNotify = (message, title) => {
  //_showingNotification = true;
  if (!title || title.length < 1) {
    title = "OK";
  }
  if (!message || message.length < 1) {
    message = "";
  }
  iziToast.success({
    title: message,
    message: title,
    position: notifyDefaults.position,
    theme: "light",
    balloon: false,
    displayMode: 2,
    timeout: vars.notifyTimeout,
  });
};
/**
 * Displays a warning pop-up notification message to the user.
 * @param {string} title [Optional] Warning message title.
 * @param {string} message Warning message.
 */
function warningNotify(title, message) {
  if (!title || title.length < 1) {
    title = "Warning";
  }
  if (!message || message.length < 1) {
    message = "";
  }

  iziToast.warning({
    title: title,
    message: message,
    position: notifyDefaults.position,
    balloon: true,
    displayMode: 2,
    timeout: vars.notifyTimeout,
  });
}
/**
 * Displays an error pop-up notification message to the user.
 * @param {string} title Error message title.
 * @param {string} message Error message.
 */
export const errorNotify = (title, message) => {
  //_showingNotification = true;
  if (!title || title.length < 1) {
    title = "Error";
  }
  if (!message || message.length < 1) {
    message = "";
  }
  iziToast.error({
    title: title,
    message: message,
    position: notifyDefaults.position,
    theme: "light",
    balloon: true,
    displayMode: 2,
    timeout: vars.notifyTimeout,
  });
};
/**
 * Display a pop-notification toast that has an image & a message to the user.
 * @param {string} title Title of the toast.
 * @param {string} message Message of the toast.
 * @param {string} imageUrl Url for the image.
 * @param {number} width Width of the image.
 */
function showImageToast(title, message, imageUrl, width) {
  width = width || 60;
  iziToast.show({
    title: title,
    titleSize: "1.1em",
    message: message,
    position: "topRight",
    theme: "light",
    backgroundColor: "#f0f2f1",
    image: imageUrl,
    imageWidth: width,
    baloon: true,
    displayMode: 2,
    layout: 2,
    timeout: vars.notifyTimeout,
  });
}
/**
 * Displays a question dialog popup for the user to respond.
 * @param {string} title Title of the question.
 * @param {string} message Question description message.
 * @param {string} okTxt Text for the Okay Button.
 * @param {string} cancelTxt Text for the Cancel button.
 * @param {Function} okCallback Callback function to execute/call if the user Clicks Okay.
 * @param {Function} cancelCallback Callback function to execute/call if the user clicks Cancel.
 */
export const questionPrompt = (
  title,
  message,
  okTxt,
  cancelTxt,
  okCallback,
  cancelCallback
) => {
  if (!title || title.length < 1) {
    title = "Please answer the question";
  }
  if (!message || message.length < 1) {
    message = "What is your response";
  }
  if (!okTxt || okTxt.length < 1) {
    okTxt = "Okay";
  }
  if (!cancelTxt || cancelTxt.length < 1) {
    cancelTxt = "Cancel";
  }
  iziToast.question({
    timeout: 30000,
    close: false,
    overlay: true,
    displayMode: 2,
    id: "question",
    zindex: 999,
    title: title,
    message: message,
    position: "center",
    buttons: [
      [
        `<button><b>${okTxt}</b></button>`,
        function (instance, toast) {
          instance.hide({ transitionOut: "fadeOut" }, toast, "button");
          if (okCallback) {
            okCallback();
          }
        },
        true,
      ],
      [
        `<button>${cancelTxt}</button>`,
        function (instance, toast) {
          instance.hide({ transitionOut: "fadeOut" }, toast, "button");
          if (cancelCallback) {
            cancelCallback();
          }
        },
      ],
    ],
  });
};
/**
 * Displays an input dialog popup for the user to enter something.
 * @param {string} title Title of the input dialog
 * @param {string} msg Description message
 * @param {string} submitBtnTxt Text for the submit Button.
 * @param {Function} onSubmit Callback function to call when the user clicks submit.
 * @param {Function} onInputChange Callback function to call when the input changes
 */
function input(title, msg, submitBtnTxt, onSubmit, onInputChange) {
  msg = msg || "";
  submitBtnTxt = submitBtnTxt || "Submit";
  iziToast.show({
    timeout: 120000,
    overlay: false,
    displayMode: 0,
    id: "inputs",
    zindex: 1061,
    title: title,
    message: msg,
    position: "center",
    drag: true,
    rtl: false,
    progressBar: false,
    inputs: [
      [
        '<input id="InputToast" type="text" placeholder="type here.."></input>',
        "keyup",
        function () {
          if (onInputChange) {
            onInputChange();
          }
        },
        true,
      ],
      [
        '<input type="checkbox">',
        "change",
        function (instance, toast, input, e) {
          console.info(input.checked);
        },
      ],
      [
        '<input type="number">',
        "keydown",
        function (instance, toast, input, e) {
          console.info(input.value);
        },
      ],
    ],
    buttons: [
      [
        `<button><b>${submitBtnTxt}</b></button>`,
        function (instance, toast) {
          if (onSubmit) {
            onSubmit($("#InputToast").val());
          }
          instance.hide({ transitionOut: "fadeOut" }, toast, "button");
        },
        true,
      ],
    ],
  });
}

export const toast = {
  success: successNotify,
  info: infoNotify,
  warning: warningNotify,
  error: errorNotify,
  showImgToast: showImageToast,
  question: questionPrompt,
  input: input,
};
