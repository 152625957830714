
export const encodeAgentUrl = (data) => {
    let url = "/agent";
      url += `?id=${data.id}`;
      url += `&code=${encodeURIComponent(data.code)}`;
      url += `&name=${encodeURIComponent(data.name)}`;
      return url;
}
export const encodeCurationUrl = (data) => {
    let url = "/list";
    url += `?id=${data.id}`;
    url += `&code=${encodeURIComponent(data.code)}`;
    url += `&name=${encodeURIComponent(data.name)}`;
    return url;
}
export const encodePropertyUrl = (data) => {
    let url = "/property";
      url += `?id=${data.id}`;
      url += `&code=${encodeURIComponent(data.code)}`;
      url += `&name=${encodeURIComponent(data.name)}`;
      return url;
}