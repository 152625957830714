import { log } from "../../../../Portal/src/js/logger";
import { http } from "../../../../Portal/src/js/http";
import { queryString } from "../../../../Portal/src/js/utils/helpers";
import { AvgRating } from '../../js/types/types';
import { encodeAgentUrl, encodePropertyUrl } from '../utils/urlEncoding';

(function () {
  $(document).ready(() => {
    const mn = $("#property-details");
    if (mn.length) {
      const dt = mn[0].dataset;
      new Vue(new PropDetailsPageVueApp());
    }
  });
})();

export class PropDetailsPageVueApp {
  constructor() {
    this.el = "#property-details";
  }
  el = "#property-details";
  data = {
    id: 0,
    item: {},
    displayImage:String,
    agent: {},
    location: {},
    images: [],
    videos: [],
    rating: AvgRating,
    reviews: [],
    map: {}
  };
  created = function () {};
  mounted = function () {
    let map = queryString();
    if (map && map.id) {
      log("mapid",map.id);
      this.id = map.id;
      this.getListing(map.id);
      // this.getMedia(map.id);
    }
    log("Build on.");
  };
  methods = {
    getListing: function (id) {
      http.get(`/api/listings/${id}`, false).then((r) => {
        log(r);
        if (r) {
          this.item=r.model;
          this.displayImage=r.model.mainImageUrl;
          this.images = r.model.images.filter(image => image.sizeType === 0);
          this.videos = r.model.videos;
          this.location=r.locale;
          this.agent=r.model.provider;
          this.populateMap();
        }
      });
    },
    getMedia: function (id) {
      http.get(`/api/listings/${id}/media`, false).then((r) => {
        // log(r);
        if (r) {
          this.images = r.images.filter(image => image.sizeType === 0);
          this.videos = r.videos;
          console.log("images",this.images)
        }
      });
    },
    changeMainImageView(url){
      this.displayImage=url;
    },
    getRating: function (id) {
      http.get(`/api/listings/${id}/rating`, false).then((r) => {
        log(r);
        if (r) {
          this.rating = r;
        }
      });
    },
    getReviews: function (id) {
      http.get(`/api/listings/${id}/reviews?page=1&skipCount=true`, false).then((r) => {
        log(r);
        if (r) {
          this.reviews = r;
        }
      });
    },

    populateMap: function(){
      let pnt = {
        lat: this.location.latitude,
        lng: this.location.longitude
      };
      this.map = new google.maps.Map(document.getElementById("myMap"), {
        center: pnt,
        zoom: 15,
      });
      this.marker = new google.maps.Marker({
        position: pnt,
        title: this.item.longLocationName,
        map: this.map,
      });
    },

    encodeUrl: function (dt) {
      return encodePropertyUrl(dt);
    },
    encodeAgentUrl: function (dt) {
      return encodeAgentUrl(dt);
    },
  };
}
