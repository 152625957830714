/**
 * Returns the query string of the current url as an object
 * of key value pairs.
 * @returns {object} Query string as an object
 */
export const queryString = () => {
  let search = location.search.substring(1);
  if (search) {
    let decoded = decodeURI(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"');
    let map = JSON.parse('{"' + decoded + '"}');
    Object.keys(map).forEach((x) => {
      map[x] = decodeURIComponent(map[x]);
    });
    return map;
  }
  return {};
};

export const changeDocTitle = (title) => {
  let prev = document.title.split("-");
  document.title = `${title} - ${prev[prev.length - 1]}`;
}