const bedRoomFilters = [
    { id: "", name: "Bedrooms" },
    { id: "1", name: "1+" },
    { id: "2", name: "2+" },
    { id: "3", name: "3+" },
    { id: "4", name: "4+" },
];
const bathRoomFilters = [
    { id: "", name: "Bathrooms" },
    { id: "1", name: "1+" },
    { id: "2", name: "2+" },
    { id: "3", name: "3+" },
    { id: "4", name: "4+" },
];
const priceFilters = [
    { id: "", name: "Max Price" },
    { id: "15000", name: "Ksh 15,000" },
    { id: "20000", name: "Ksh 20,000" },
    { id: "30000", name: "Ksh 30,000" },
    { id: "40000", name: "Ksh 40,000" },
    { id: "90000", name: "Ksh 90,000" },
    { id: "140000", name: "Ksh 140,000" },
    { id: "1400000", name: "Ksh 1,400,000" },


];
const sortOptions = [
    { id: "", name: "Sort By" },
    { id: "newest", name: "Newest" },
    { id: "oldest", name: "Oldest" },
    { id: "+price", name: "Least Price" }, // ascending order
    { id: "-price", name: "Highest Price" }, // descending order
    { id: "views", name: "Popularity" },
];
const agentSortOptions = [
    { id: "", name: "Sort By" },
    { id: "newest", name: "Newest" },
    { id: "oldest", name: "Oldest" },
    { id: "views", name: "Popularity" },
    { id: "rating", name: "Rating" },
];

export const filterQueries = {
    q: '',
    channel: '',
    sortBy: '',
    maxPrice: '',
    bedrooms: '',
    bathrooms: '',
    propertyTypeId: ''
}
export const agentFilterQueries = {
    q: '',
    sortBy: '',
}
export const filterOptions = {
    bedRoomFilters, bathRoomFilters, priceFilters, sortOptions, agentSortOptions
};