import { log } from "../../../../Portal/src/js/logger";
import { http } from "../../../../Portal/src/js/http";
import { formatNumber } from "../../../../Portal/src/js/utils/numbers";
import { queryString } from "../../../../Portal/src/js/utils/helpers";
import { filterOptions, filterQueries } from '../utils/constants';
import { encodeAgentUrl, encodePropertyUrl } from '../utils/urlEncoding';
import { toast } from "../../../../Portal/src/js/toast";

const _elemId = '#curation-listing-details';

(function () {
    $(document).ready(() => {
        const mn = $(_elemId);
        if (mn.length) {
            const dt = mn[0].dataset;
            new Vue(new CurationsPageVueApp());
        }
    });
})();

export class CurationsPageVueApp {
    constructor() {
        this.el = _elemId;
        this.data = Object.assign({}, this.data, filterOptions);
        this.data.query = Object.assign({}, this.data.query, filterQueries);
    }
    el = _elemId;
    data = {
        id: 0,
        paged: {},
        item: {},
        items: [],
    };
    created = function () { };
    mounted = function () {
        let map = queryString();
        if (map) {
            this.item.id = map.id;
            this.item.code = map.code;
            this.item.name = map.name;
            this.query = Object.assign({}, this.query, map);
        }
        this.getItem();
        this.getItems();
        $('select.nice').niceSelect();
    };
    methods = {
        getItem: function () {
            http.get(`/api/curations/${this.item.id}`, false).then((r) => {
                log(r);
                if (r) {
                    this.item = Object.assign({}, this.item, r);
                    document.title = `${r.name} (${formatNumber(r.totalProperties)} properties)`;
                }
            });
        },
        getItems: function () {
            let str = this.getQueryString();
            http.get(`/api/curations/${this.item.id}/listings?${str}`, false).then((r) => {
                if (r) {
                    this.paged = r;
                }
            });
        },
        getQueryString: function () {
            const aa = this.query;
            let bb = "";
            Object.keys(this.query).forEach((x) => {
                if (aa[x] != "" || aa[x] != undefined) {
                    bb += `&${x}=${aa[x]}`;
                }
            });
            bb = bb.substr(1);
            log(bb);
            return bb;
        },

        like: function () {
            http.post(`/api/curations/${this.item.id}/like`).then((r) => {
                log(r);
                if (r) {
                    toast.success('Thank you!', 'This list will now be more accessible to other people.');
                }
            });
        },

        encodeUrl: function (dt) {
            return encodePropertyUrl(dt);
        },
        encodeAgentUrl: function (dt) {
            return encodeAgentUrl(dt);
        },
    };
}
