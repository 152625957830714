import { log } from "../../../../Portal/src/js/logger";
import { http } from "../../../../Portal/src/js/http";
import { queryString } from "../../../../Portal/src/js/utils/helpers";
import { encodeAgentUrl } from "../utils/urlEncoding";
import { filterOptions, agentFilterQueries } from '../utils/constants';


(function () {
  $(document).ready(() => {
    const mn = $("#agent-listings");
    if (mn.length) {
      const dt = mn[0].dataset;
      new Vue(new AgentListingsPageVueApp());
    }
  });
})();

export class AgentListingsPageVueApp {
  constructor() {
      this.el = "#agent-listings";
      this.data = Object.assign({}, this.data, filterOptions);
      this.data.query = Object.assign({}, this.data.query, agentFilterQueries);
  }
  el = "#agent-listings";
  data = {
    id: 0,
    query: {},
    paged: {},
    items: [],
  };
  created = function () { };
  mounted = function () {
    let map = queryString();
    if (map) {
        this.query = Object.assign({}, this.query, map);
    }
    this.getListings();
    log("Build on.");
  };
  methods = {
    getListings: function () {
      let str = this.getQueryString();
      http.get(`/api/providers?${str}`, false).then((r) => {
        log(r);
        if (r) {
          // this.paged = r;
          this.paged = r.items.filter(item => item.typeName === "Agent");
        }
      });
    },
    getQueryString: function () {
      const aa = this.query;
      let bb = "";
      Object.keys(this.query).forEach((x) => {
        if (aa[x] != "" || aa[x] != undefined) {
          bb += `&${x}=${aa[x]}`;
        }
      });
      bb = bb.substr(1);
      log(bb);
      return bb;
    },

    encodeAgentUrl: function (dt) {
      return encodeAgentUrl(dt);
    },
  };
}
