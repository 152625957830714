function startPacer() {
  if ($(".pace").length) {
    let elem = $(".pace");
    if (!elem.hasClass("play")) {
      elem.addClass("play");
    }
  }
}
function stopPacer() {
  if ($(".pace").length) {
    let elem = $(".pace");
    if (elem.hasClass("play")) {
      elem.removeClass("play");
    }
  }
}
/**
 * Provides methods for working with the pacer control.
 */
export const pacer = {
  /**
   * Toggles the pacer control to start running thus
   * indicating progress or activity.
   */
  start: startPacer,
  /**
   * Checks if the pacer control is currently running
   * and stops it.
   */
  stop: stopPacer,
};
