import { log } from "./logger";
import { pacer } from "./pacer";
import { vars } from "./variables";
import { toast } from "./toast";

/**
 * Makes an Ajax Http GET request and returns a promise
 * @param {string} uri Url
 * @param {boolean} notifyOnError Show error notification if it fails.
 * @returns {Promise} Promise object.
 */
function httpGet(uri, notifyOnError = true) {
  const promise = new Promise(function (resolve, reject) {
    $.ajax({
      method: "GET",
      url: uri,
      crossDomain: true,
      timeout: vars.ajaxTimeout,
      success(res) {
        pacer.stop();
        resolve(res);
      },
      error(err) {
        reject(err);
      },
      beforeSend(s) {
        addDefaultHeaders(s);
        pacer.start();
      },
    })
      .done(() => {
        pacer.stop();
      })
      .fail((e) => {
        if (notifyOnError) {
          onHttpError(e);
        }
        pacer.stop();
      });
  });
  return promise;
}
/**
 * Makes an Ajax Http POST request and returns a promise object.
 * @param {string} url Url
 * @param {any} data Data to Post
 * @param {string} type @default 'application/json' Content-Type
 * @returns {Promise} Promise object
 */
function httpPost(url, data, type, progressHandler) {
  if (!type) {
    type = type || vars.contentType;
  } else if (type == "-1") {
    type = null;
  }
  const promise = new Promise(function (resolve, reject) {
    $.ajax({
      method: "POST",
      url: url,
      data: data,
      processData: false,
      contentType: type ? type : false,
      xhr: function () {
        var xhr = new window.XMLHttpRequest();
        xhr.upload.addEventListener(
          "progress",
          function (evt) {
            if (progressHandler) {
              progressHandler(evt);
            }
          },
          false
        );
        return xhr;
      },
      success(res) {
        pacer.stop();
        resolve(res);
      },
      error(err) {
        reject(err);
      },
      beforeSend(s) {
        addDefaultHeaders(s);
        pacer.start();
      },
    })
      .done(() => {
        pacer.stop();
      })
      .fail((e) => {
        onHttpError(e);
        pacer.stop();
      });
  });
  return promise;
}
/**
 * Makes an Ajax Http PUT request and returns a promise.
 * @param {string} url Url
 * @param {any} data Data to Post
 * @param {string} type @default 'application/json' Content-Type
 * @returns {Promise} Promise object
 */
function httpPatch(url, data, type) {
  if (!type) {
    type = type || vars.contentType;
  } else if (type == "-1") {
    type = null;
  }
  const promise = new Promise(function (resolve, reject) {
    $.ajax({
      method: "PATCH",
      url: url,
      data: data,
      processData: false,
      contentType: type ? type : false,
      success(res) {
        pacer.stop();
        resolve(res);
      },
      error(err) {
        reject(err);
      },
      beforeSend(s) {
        addDefaultHeaders(s);
        pacer.start();
      },
    })
      .done(() => {
        pacer.stop();
      })
      .fail((e) => {
        onHttpError(e);
        pacer.stop();
      });
  });
  return promise;
}
/**
 * Makes an Ajax Http DELETE request and returns a promise
 * @param {String} uri Url
 * @param {any} data Data to Post
 * @param {string} type @default 'application/json' Content-Type
 * @returns {Promise} Promise object.
 */
function httpDelete(uri, data, type) {
  if (!type) {
    type = type || vars.contentType;
  } else if (type == "-1") {
    type = null;
  }
  const promise = new Promise(function (resolve, reject) {
    $.ajax({
      method: "DELETE",
      url: uri,
      data: data,
      processData: false,
      crossDomain: true,
      contentType: type ? type : false,
      success(res) {
        pacer.stop();
        resolve(res);
      },
      error(err) {
        reject(err);
      },
      beforeSend(s) {
        addDefaultHeaders(s);
        pacer.start();
      },
    })
      .done(() => {
        pacer.stop();
      })
      .fail((e) => {
        onHttpError(e);
        pacer.stop();
      });
  });
  return promise;
}

/**
 * Adds the default & often required headers before making
 * any API calls.
 * @param {JQuery.jqXHR<any>} xhr Ajax request
 */
function addDefaultHeaders(xhr) {
  //   let token = auth.accessTokenFactory();

  //   if (token) {
  //     xhr.setRequestHeader("Authorization", `Bearer ${token}`);

  //     if (window.App) {
  //       xhr.setRequestHeader("X-APP-ID", window.App.Id);

  //       xhr.setRequestHeader("X-APP-CODE", window.App.Code);
  //     }
  //   } else {
  //     xhr.abort();
  //   }
  return xhr;
}

/**
 * Handles failed http requests
 * @param {JQuery.jqXHR} e
 */
export const onHttpError = (e) => {
  log(e);
  if (e.statusText == "abort") {
    toast.error(
      "Aborted!",
      "Request could not be completed. Please try again."
    );
  } else if (e.statusText == "timeout") {
    toast.error("Timeout!", "Please refresh to try again.");
  } else if (e.status == 0) {
    toast.error("Server Unreachable!", "Please refresh to try again.");
  } else {
    let tt = "";
    let msg = "";
    if (e.status == 400) {
      if (e.responseJSON) {
        if (e.responseJSON.errors) {
          handleModelStateErrors(e.responseJSON.errors);
          return;
        } else if (e.responseJSON.title) {
          tt = e.responseJSON.title;
        } else {
          tt = e.responseText;
        }
      } else if (e.responseText) {
        tt = e.responseText;
      }
    } else if (e.status == 401) {
      if (e.responseJSON) {
        tt = e.responseJSON.code;
        msg = e.responseJSON.message;
        if (msg.includes("Access token is required")) {
          msg = "Please login first before making this request.";
        }
      } else {
        tt = "UnAuthorized";
        msg = e.responseText ? e.responseText : "";
      }
    } else if (e.status == 403) {
      let json = e.responseJSON;
      if (json) {
        if (json.errorCode >= 4100 && json.errorCode <= 4199) {
          //window.location = `${vars.errorRedirectPath}?${$.param(json)}`;
          return;
        }
        tt = json.name;
        msg = json.message;
      } else {
        tt = "Forbidden";
        msg = e.responseText ? e.responseText : "";
      }
    } else if (e.status == 404) {
      tt = "404 Error";
      if (e.responseText && e.responseText.length <= 100) {
        msg = e.responseText;
      } else if (e.responseJSON) {
        msg = "Unable to get the requested data.";
      }
    } else if (e.status == 415) {
      if (e.responseJSON) {
        tt = e.responseJSON.title;
        msg = "Data sent in wrong format";
      } else {
        tt = "Developer Error";
        msg = "Data submitted in unsupported format";
      }
    } else if (e.status == 500) {
      tt = "Oops! Failed";
      msg = "An internal server error occured.";
      if (e.responseJSON) {
        tt = "Error";
        msg = e.responseJSON.detail;
      } else if (e.responseText && e.responseText.length <= 100) {
        msg = e.responseText;
      }
    } else {
      if (e.responseText && e.responseText.length <= 100) {
        msg = e.responseText;
      } else {
        tt = e.statusText;
      }
    }
    toast.error(tt, msg);
  }
};
function handleModelStateErrors(err) {
  if (err) {
    let pairs = [];
    Object.keys(err).forEach((x) => {
      pairs.push({ key: x, value: err[x].join(",") });
    });
    log(pairs);
    let msg = `${pairs[0].key} - ${pairs[0].value}`;
    toast.error("Validation Error", msg);
  }
}

export const http = {
  get: httpGet,
  post: httpPost,
  patch: httpPatch,
  delete: httpDelete,
  addDefaultHeaders: addDefaultHeaders,
};
