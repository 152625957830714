import { log } from "../../../../Portal/src/js/logger";
import { http } from "../../../../Portal/src/js/http";
import { encodeAgentUrl, encodeCurationUrl, encodePropertyUrl } from '../utils/urlEncoding';

(function () {
	$(document).ready(() => {
		const mn = $("main");
		if (mn.length) {
			const dt = mn[0].dataset;
			new Vue(new LandingPageVueApp());
		}
	});
})();

export class LandingPageVueApp {
	constructor() {
		this.el = "main";
	}
	el = "main";
	data = {
		agents: {},
		listings: {},
		curations: {},
		suburbs: [],
		suburbsresult: [],
		selectedResult:'',
		propertytypes: [],
		query: {
			q: "",
			page: 1,
			perpage: 10,
		},
		filter: {
			q: '',
			propertyTypeId: '',
			bedrooms: '',
			maxPrice: '',
			propTypeHasBedrooms: true
        }
	};
	created = function () {

	};
	mounted = function () {
		$('select.nice').niceSelect();
		this.getPropTypes();
		this.getAgents();
		this.getListings();
		this.getCurations();
		this.getSuburbs();
	};
	methods = {
		getAgents: function () {
			http.get("/api/featured/agents", false).then((r) => {
				this.agents = r;
			});
		},
		getListings: function () {
			http.get("/api/featured/listings", false).then((r) => {
				this.listings = r;
				this.startListingsSlider();
			});
		},
		getCurations: function () {
			http.get("/api/curations?skipCount=true", false).then((r) => {
				//log(r);
				this.curations = r;
			});
		},
		getPropTypes: function () {
			http.get("/api/propertytypes", false).then((r) => {
				log(r);
				this.propertytypes = r;
			});
		},
		getSuburbs: function () {
			http.get("/api/suburbs?skipCount=true", false).then((r) => {
				// this.suburbs = r;
				// this.suburbs = r.filter(suburb => suburb.name !== null);
                const filteredSuburbs = r.filter((suburb, index, self) =>
                  suburb.name !== null && index === self.findIndex(s => s.name === suburb.name)
                );
                this.suburbs = filteredSuburbs;
			});
		},
		getSuburbsSearch:function(){
		   let param=this.filter.q;
		   if(param){
		   	http.get("/api/suburbs-by-name?name="+this.filter.q, false).then((r) => {
				var name = r.map(obj => obj.name);
                var locality = r.map(obj => obj.locality);
                var sublocality = r.map(obj => obj.subLocality);
				var resultsSet = new Set(name.concat(locality, sublocality));
                this.suburbsresult= Array.from(resultsSet)
			});
		   }
		},
        selectLocation:function(event,selected){
			this.selectedResult=selected
            this.filter.q=selected;
            this.suburbsresult=[]
		},
		homePageSearch:function(){
        //  window.location.href="property/listings?locationId="+this.selectedResult.id
         window.location.href="property/listings?locationName="+this.selectedResult
		},
		onPropertyTypeChange: function (e, id) {
			log(id);
			log(e);
			if (id) {
				const prop = this.propertytypes.find(x => x.id == id);
				if (prop) {
					if (prop.hasBedrooms) {
						this.filter.propTypeHasBedrooms = true;
					} else {
						this.filter.propTypeHasBedrooms = false;
						this.filter.bedrooms = '';
					}
                }
            }
        },

		encodeUrl: function (dt) {
			return encodePropertyUrl(dt);
		},
		encodeAgentUrl: function (dt) {
			return encodeAgentUrl(dt);
		},
		encodeCurationUrl: function (dt) {
			return encodeCurationUrl(dt);
		},
		startListingsSlider: function () {
			$(".recent-property-slider").slick({
				autoplay: false,
				speed: 800,
				autoplaySpeed: 3500,
				slidesToShow: 3,
				pauseOnHover: true,
				centerMode: false,
				cssEase: 'linear',
				infinite: true,
				centerPadding: '0px',
				dots: false,
				arrows: true,
				cssEase: 'ease',
				speed: 700,
				draggable: true,
				prevArrow: '<button class="Prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
				nextArrow: '<button class="Next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
				responsive: [{
					breakpoint: 1200,
					settings: {
						arrows: false,
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 800,
					settings: {
						arrows: true,
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 600,
					settings: {
						arrows: false,
						slidesToShow: 1,
					}
				},
				{
					breakpoint: 350,
					settings: {
						arrows: false,
						slidesToShow: 1,
					}
				},
				]
			});
		},
	};
}
