class _vars {
  constructor() {
    this.debug = true;
    this.cookieExpInDays = 0;
    this.cacheExpTimeInMins = 0;
    this.notifyTimeout = 5000;
    this.ajaxTimeout = 30000;
    this.contentType = "application/json";
    this.addModalElemId = "#addModal";
    this.moneyPrefix = "Ksh";
    this.decimalPlaces = 1;
    this.numberFormat = "en-US";
  }
}

export const vars = new _vars();
