import { vars } from "./variables";

/**
 * Log information/data to the developer console
 * @param {any} obj Any type of data.
 * @param {string} tag Tag for identifying data in the console.
 */
export const log = (obj, tag) => {
  if (!vars.debug) return;
  if (!tag) {
    tag = "I";
  }

  if (tag === "OBJ") return;
  if (typeof obj === "Number" || typeof obj === "string") {
    console.log(obj);
  } else {
    console.log(tag, obj);
  }
};
/**
 * Prints out a warning message to the developer console
 * @param {string} msg Message to print
 * @param {any} data Additional data to log.
 */
export const logWarning = (msg, data) => {
  if (!vars.debug) return;
  if (!msg) {
    msg = "WARN";
  }
  console.warn(msg, data);
};

export const logger = {
  info: log,
  warning: logWarning,
};
