import { truncate, formatDecimals } from '../../js/globals/formats';


Vue.filter("N0", function (val) {
    return formatDecimals(val, 0);
});
Vue.filter("N1", function (val) {
    return formatDecimals(val, 1);
});
Vue.filter("roundOff", function (val, decimals = 1) {
    return formatDecimals(val, decimals);
});

Vue.filter("money", function (val) {
    return 'Ksh ' + formatDecimals(val, 0);
});

Vue.filter("truncate", function (val, count) {
    return truncate(val, count);
});

Vue.filter("toNow", function (dt) {
    if (!dt) return "";
    let val = moment.utc(dt).local();
    return val.toNow(true);
});
Vue.filter("dashesIfNull", function (dt) {
    if (!dt) return "--";
    return dt;
});
Vue.filter("toMoment", function (dt) {
    if (!dt) return "";
    let val = moment.utc(dt).local();
    return momentify(val);
});
function momentify(val) {
    let d = moment().diff(val, "seconds");
    if (d >= 0) {
        const hr = 60 * 60;
        const day = hr * 24;
        const wk = day * 7;
        const mnth = wk * 4;
        if (d < 60) {
            return d <= 10 ? "Just now" : `${d} secs ago`;
        } else if (d < hr) {
            let m = parseInt(d / 60);
            return `${m} ${m == 1 ? "min" : "mins"} ago`;
        } else if (d < day) {
            // one day
            let h = parseInt(d / 3600);
            return `${h} ${h == 1 ? "hr" : "hrs"} ago`;
        } else if (d < wk) {
            // one week
            return val.format("ddd h:mm A");
        } else if (d < mnth) {
            // one month
            return val.format("DD MMM YYYY");
        }
    } else {
        return moment().to(val);
    }
    return val.format("D/M/YYYY");
}