Vue.component('pager', {
    props: ['vm'],
    template: `
        <div class="pagination-main">
            <ul class="pagination">
                <li v-if="canGoBack()" class="prev"><a role="button" v-on:click="goBack"><i class="fa fa-angle-left"></i></a></li>
                <li v-for="pg in getPages()"><a role="button" v-on:click="goToPage(pg)">1</a></li>
                <li v-if="canGoNext()" class="next"><a role="button" v-on:click="goNext"><i class="fa fa-angle-right"></i></a></li>
            </ul>
        </div>
    `,
    methods: {
        goBack: function () {
            this.vm.query.page--;
            this.vm.getItems();
        },
        goNext: function () {
            this.vm.query.page++;
            this.vm.getItems();
        },
        goToPage: function (page) {
            this.vm.query.page = page;
            if (page != this.vm.paged.page) {
                this.vm.getItems();
            }
        },
        canGoBack: function () {
            return this.vm.paged.page > 1;
        },
        canGoNext: function () {
            return this.vm.paged.totalPages > this.vm.paged.page;
        },
        getPages: function () {
            let arr = [];
            for (let i = 1; i <= this.vm.paged.totalPages; i++) {
                arr.push(i);
            }
            return arr;
        }
    }
});