import { log } from "../../../../Portal/src/js/logger";
import { http } from "../../../../Portal/src/js/http";
import {
  queryString,
  changeDocTitle,
} from "../../../../Portal/src/js/utils/helpers";
import { AvgRating } from "../../js/types/types";
import { encodeAgentUrl, encodePropertyUrl } from "../utils/urlEncoding";
import { toast } from "../../../../Portal/src/js/toast";
import { filterOptions, filterQueries } from '../utils/constants';

const _wrapperId = "#agent-details";

(function () {
  $(document).ready(() => {
    if ($(_wrapperId).length) {
      new Vue(new AgentDetailsPageVueApp());
    }
  });
})();

export class AgentDetailsPageVueApp {
  constructor() {
      this.el = _wrapperId;
      this.data = Object.assign({}, this.data, filterOptions);
      this.data.query = Object.assign({}, this.data.query, filterQueries);
  }
  el = _wrapperId;
  data = {
    id: 0,
    item: {},
    paged: {},
    location: {},
    images: [],
    videos: [],
    rating: AvgRating,
    reviews: [],
    review: {},
    map: {},
    rating: 1,
    propertytypes: [],
    grade: 1,
    stars: 0,
    maxStars: 5,
      hasCounter: false,
      query: {
          q: '',
          sortBy: '',
          propertyTypeId: '',
          bedrooms: '',
          maxPrice: '',
          propTypeHasBedrooms: true
      }
  };
  created = function () { };
  mounted = function () {
    let map = queryString();
    if (map && map.id) {
      this.id = map.id;
      this.getAgent(map.id);
      
      this.getRatings(map.id);
        this.getPropTypes();
        this.getItems();
    }
  };
  methods = {
    getAgent: function (id) {
      http.get(`/api/providers/${id}`, false).then((r) => {
        if (r) {
          this.item = r.model;
          this.location = r.model.location;
          changeDocTitle(r.model.name);
          this.populateMap();
        }
      });
    },
    getItems: function () {
      let str = this.getQueryString();
      http.get(`/api/providers/${this.id}/listings?${str}`, false).then((r) => {
        if (r) {
          this.paged = r;
        }
      });
    },
    getQueryString: function () {
      const aa = this.query;
      let bb = "";
      Object.keys(this.query).forEach((x) => {
        if (aa[x] != "" || aa[x] != undefined) {
          bb += `&${x}=${aa[x]}`;
        }
      });
      bb = bb.substr(1);
      log(bb);
      return bb;
    },
    getPropTypes: function () {
      http.get(`/api/propertytypes`, false).then((r) => {
        if (r) {
          this.propertytypes = r;
        }
      });
    },
    getMedia: function (id) {
      http.get(`/api/providers/${id}/media`, false).then((r) => {
        log(r);
        if (r) {
          this.images = r.images;
          this.videos = r.videos;
        }
      });
    },
    getRatings: function (id) {
      http.get(`/api/providers/${id}/ratings`, false).then((r) => {
        if (r) {
          this.rating = r.aggregateScore;
        }
      });
    },
    getReviews: function (id) {
      http
        .get(`/api/listings/${id}/reviews?page=1&skipCount=true`, false)
        .then((r) => {
          log(r);
          if (r) {
            this.reviews = r;
          }
        });
    },

      onSearch: function (e) {
          e.preventDefault();
          this.getItems();
      },
      onPropertyTypeChange: function (e, id) {
          log(id);
          log(e);
          if (id) {
              const prop = this.propertytypes.find(x => x.id == id);
              if (prop) {
                  if (prop.hasBedrooms) {
                      this.query.propTypeHasBedrooms = true;
                  } else {
                      this.query.propTypeHasBedrooms = false;
                      this.query.bedrooms = '';
                  }
              }
          }
      },

      likeAgent: function (dt) {
      if (dt) {
        dt.likes++;
        http.post(`/api/providers/${dt.id}/like`).then((r) => {
          log(r);
        }).catch(() => {
          dt.likes--;
          toast.error('Unable to like!', 'Please try again later.');
        });
      }
    },
    reviewAgent: function (dt) {
      log(dt);
      toast.info('Review Agent', 'Coming soon.');
    },
    getRatingStar: function (pos) {
      let css = 'ion-ios-star';
      const fx = this.rating + 1 - pos;
      css += fx <= 0 ? '-outline'
        : fx > 0 && fx < 1
          ? '-half' : '';
      return css;
    },
    setRatingValue: function (star) {
      if (typeof star === 'number' && star <= this.maxStars && star >= 0) {
        this.stars = this.stars === star ? star - 1 : star;
      }
    },
    submitReview: function (e) {
      e.preventDefault();
      let data = {
        score: this.stars,
        title: this.review.title,
        content: this.review.content
      };
      log(data);
      if (data.score <= 0) {
        toast.error('Invalid', 'Rating score is required.');
        return;
      }
      http.post(`/api/providers/${this.id}/review`, JSON.stringify(data)).then((r) => {
        log(r);
        if (r) {
          this.rating = r;
        }
        $('#ratingModal').modal('hide');
      }).catch(() => {
        toast.error('Unable to review!', 'Please try again later.');
      });
    },

    populateMap: function () {
      let pnt = {
        lat: this.location.latitude,
        lng: this.location.longitude,
      };
      this.map = new google.maps.Map(document.getElementById("myMap"), {
        center: pnt,
        zoom: 15,
      });
      this.marker = new google.maps.Marker({
        position: pnt,
        title: this.item.longLocationName,
        map: this.map,
      });
    },
    encodeUrl: function (dt) {
      return encodePropertyUrl(dt);
    },
    encodeAgentUrl: function (dt) {
      return encodeAgentUrl(dt);
    },
  };
}
